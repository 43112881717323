import { Image } from "@doar/components";
import { StyledLogo } from "./style";

const Logo = () => {
    return (
        <StyledLogo path="/">
            <Image
                src="../../../../logo.png"
                alt="logo"
                paddingTop={"10px"}
                paddingBottom={"10px"}
            />
            {/* do<span>ar</span> */}
        </StyledLogo>
    );
};

export default Logo;
